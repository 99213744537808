import { useRuntimeConfig } from '#app'
import type { ModuleOptions } from '~/utils/permissions/types'

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (process.client) { return }
  const { $hasRequiredPermissions, $hasRequiredRoles } = useNuxtApp()
  const config = useRuntimeConfig().public.rmaPermissions as ModuleOptions
  const routePermissions = to.meta?.permissions as string | string[] | undefined
  const routeRoles = to.meta?.roles as string | string[] | undefined

  if (!routePermissions && !routeRoles) {
    return true
  }

  if (routePermissions && $hasRequiredPermissions(routePermissions)) {
    return true
  }

  if (routeRoles && $hasRequiredRoles(routeRoles)) {
    return true
  }

  if (!config.redirectIfNotAllowed) {
    if (from.fullPath !== to.fullPath) {
      return from.fullPath
    }
    return false
  }
  return config.redirectIfNotAllowed || '/'
})
